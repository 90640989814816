<template>
	<h1>Donate</h1>
	<p>
		Arthur is a free and advertising-free service. To help us cover expenses starting out, we accept
		donations.
	</p>
	<p>
		For sustained support and early access, join us on Patreon.
	</p>
	<p>To those of you who have donated, THANK YOU &nbsp;<small class="heart">&hearts;</small></p>
	<br /><br />
	<a target="_blank" href="https://www.buymeacoffee.com/butisitarthur"
		><FormButton :level="1" value="Buy us a coffee" class="bump-r bump-b"
	/></a>
	<a target="_blank" href="https://www.patreon.com/butisitarthur"
		><FormButton :level="1" value="Support us on Patreon"
	/></a>

	<p class="small">
		<br />
		You can also donate via
		<a target="_blank" href="https://paypal.me/themoenen">PayPal</a>,
		<a target="_blank" href="https://venmo.com/u/themoenen">Venmo</a> or
		<a target="_blank" href="https://cash.app/$themoenen">Cash App</a>.
	</p>
</template>

<script>
import FormButton from '@/components/FormButton'
import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'GeneralDonate',
	components: { FormButton },
	setup() {
		injectMetaData({
			title: 'Arthur.io • Donate',
		})
	},
	methods: {
		test() {
			this.$router.replace({ name: 'Contact' })
		},
	},
}
</script>

<style scoped lang="scss">
.heart {
	font-family: san-serif;
	color: $black;
}
</style>
